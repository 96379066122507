<template>
<v-app>
  <div class="mt-4">
      <div>
      <v-container>
      <h2 class="mt-2 instructions-m">Instructions for MacOS</h2>
      <v-card class="mt-2">
          <div class="mx-3">
      
          <div class="mt-2">
              <!-- Instructions -->
           <v-list class="detail-listing">
                    <h6 class="text-secondary small mb-3 read-instructions">Read the instructions carefully to download Staff Timer Silent App</h6>
                   <v-list-item-subtitle>
                    <span class="numbring">1.</span> Run this command in your MAC Terminal <code class="color-code"> echo "{{this.$route.params.id}}" > /tmp/com.sta && chmod -R 777 /Applications/SilentApp.app/ && xattr -dr com.apple.quarantine /Applications/SilentApp.app </code>
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">2.</span> Run SilentApp by using right click to open.
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">3.</span> Permissions of accessibility (silent app and terminal), input monitor and screen monitor.
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">4.</span> Give permissions to the app. Click on the <a href="https://stafftimerapp.com/download-app-for-mac" target="_blank">Link</a> for permissions instructions.
                  </v-list-item-subtitle>     
              </v-list>   
          </div>
          <v-divider></v-divider>
              <!-- Download button -->
              <v-btn  class="mb-3 instructions-download" target="_blank" href="https://api.stafftimerapp.com/download/app/invisible/mac" download elevation="0" outlined height="40" color="#2758f6">Download</v-btn>
          </div>
         </v-card>
         </v-container>
         <!-- Download Faqs -->
         <div class="my-4">
         <v-container class="mt-3">
      <h2 class="mt-3 instructions-m">Download & Installation FAQ </h2>
      <v-card class="my-2">
          <template>
            <v-expansion-panels focusable inset>
                <!-- Question 1 -->
                <v-expansion-panel>
                <v-expansion-panel-header class="bold">
                    How will users be notified or confirm that the silent app is successfully installed on their machine?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Once the app is successfully installed, your machine will start to send required data to your account,
                    from there you will know that the silent app is working successfully.
                </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Question 2 -->
                <v-expansion-panel>
                <v-expansion-panel-header class="bold">
                    How to uninstall the silent app?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    To uninstall the silent app from mac please contact our support.
                </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Question 3 -->
                <v-expansion-panel>
                <v-expansion-panel-header class="bold">
                    Can a user see the silent app installed on its machine?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    As this app has no Graphical User Interphase, so the user won't be able to see the app on their computers. However, user can just see the application's icon on their machines. 
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            </template>
         </v-card>
         </v-container>
         </div>
         <v-divider></v-divider>
         </div>
  </div>
</v-app>
</template>

<script>
export default {
  name: "download-mac-silentapp",
  methods:{
      showDownloadModal(){
          this.$refs.downloadModal.show()
      },
  }
};
</script>

<style scoped>
.numbring {
    float: left;
    padding-right: 9px;
}
.instructions-download {
    text-align: left !important;
    width: 170px;
    margin: 18px 0;
}
.read-instructions{
    color: #6c757d !important;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    padding-bottom: 15px;
}
.instructions-m{
    text-align: left;
    padding: 13px 0;
}
.detail-listing{
    text-align: left;
    padding: 10px 18px;
}
.detail-listing .v-list-item__subtitle{
    line-height: 34px;
    font-size: 16px;
    white-space: normal;
}
.color-code{
   color: #e83e8c !important;
}
.bold{
    font-weight: 600!important;
}
</style>
